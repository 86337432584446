import ProposalCalculator from '@/calc/ProposalCalculator';
import ProposalSolveAmountForEnum from '@/enums/proposal/solveAmountFor';

export class ProposalFeesDeductedCalculator extends ProposalCalculator {
    constructor(properties = {}) {
        super();
        for (const key in properties) {
            if (properties[`${key}`] !== null && properties[`${key}`] !== undefined) {
                this[`${key}`] = properties[`${key}`];
            }
        }
    }

    _solve_amount_for = ProposalSolveAmountForEnum.CLEARED_FUNDS;

    get interim_percentage() {
        let percentage = this.$m(1, { precision: 8 });

        percentage = percentage.add(this.initial_adviser_fee_percentage);

        percentage = percentage.add(
            this.$m(this.annual_adviser_fee_percentage, { precision: 8 }).multiply(
                this.annual_adviser_fee_years_upfront
            )
        );

        percentage = percentage.add(
            this.$m(this.annual_platform_fee_percentage, { precision: 8 }).multiply(
                this.annual_platform_fee_years_upfront
            )
        );

        return percentage.value;
    }

    get interim_amount() {
        return this.$m(this._initial_amount, { precision: 8 }).divide(this.interim_percentage).value;
    }

    recalculate() {
        this.recalculateFees();
        this.recalculateTotals();
    }

    recalculateTotals() {
        this.recalculateAmount();
        this.recalculateApplicationAmount();
        this.recalculateClearedFunds();
    }

    recalculateAmount() {
        this._amount = this.$m(this.initial_amount, { precision: 8 })
            .subtract(this.initial_adviser_fee_total)
            .subtract(this.annual_adviser_fee_total)
            .subtract(this.annual_platform_fee_total).value;

        this.log('Recalculated amount: ', {
            calculation: `${this.$m(this.initial_amount, { precision: 8 }).value} - ${
                this.initial_adviser_fee_by_total
            } - ${this.annual_adviser_fee_by_total} - ${this.annual_platform_fee_by_total}`,
            output: this._amount
        });
    }

    recalculateApplicationAmount() {
        this._application_amount = this.$m(this.amount, { precision: 8 }).add(
            this.initial_adviser_fee_by_collection
        ).value;

        this.log('Recalculated application amount: ', {
            calculation: `${this.$m(this.amount, { precision: 8 }).value} + ${this.initial_adviser_fee_by_collection}`,
            output: this._application_amount
        });
    }

    recalculateClearedFunds() {
        this._cleared_funds = this.$m(this.application_amount, { precision: 8 })
            .add(this.annual_adviser_fee_by_collection)
            .add(this.annual_platform_fee_by_collection).value;

        this.log('Recalculated cleared funds: ', {
            calculation: `${this.$m(this.application_amount, { precision: 8 }).value} + ${
                this.annual_adviser_fee_by_collection
            } + ${this.annual_platform_fee_by_collection}`,
            output: this._cleared_funds
        });
    }

    /**
     * Initial Amount
     * @param {number} value
     */
    set initial_amount(value) {
        super.initial_amount = value;
        this.recalculateFees();
        this.recalculateAmount();
        this.recalculateApplicationAmount();
        this.recalculateClearedFunds();
    }
    get initial_amount() {
        return super.initial_amount;
    }

    /**
     * Investment Amount
     * @param {number} value
     */
    set amount(value) {
        super.amount = value;
        this.recalculateApplicationAmount();
        this.recalculateClearedFunds();
    }
    get amount() {
        return super.amount;
    }

    /**
     * Application Amount
     * @param {number} value
     */
    set application_amount(value) {
        throw new Error('Cannot set application_amount.');
    }
    get application_amount() {
        return super.application_amount;
    }

    /**
     * Cleared Funds
     * @param {number} value
     */
    set cleared_funds(value) {
        throw new Error('Cannot set application_amount.');
    }
    get cleared_funds() {
        return super.cleared_funds;
    }
}

export default ProposalFeesDeductedCalculator;
