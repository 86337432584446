import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';
import { Investor } from '@/models';
import FatalErrorTypeEnum from '@/enums/fatalErrorType';

const redirectSuspendedOrganisation = async (to, from, next) => {
    if (isPublic(to)) {
        next();
    } else {
        const auth = Auth();

        if (auth.is_initialised && auth.is_investor && auth.is_owner && to.name !== 'FatalError') {
            const investor = await Investor.$get(auth.organisation_id);

            if (investor.is_suspended) {
                next({
                    name: 'FatalError',
                    query: {
                        type: FatalErrorTypeEnum.SUSPENDED,
                        signout_url: auth.signout_url
                    }
                });
            } else {
                next();
            }
        } else {
            next();
        }
    }
};

export default redirectSuspendedOrganisation;
