import BaseApi from '@/api/BaseApi';
import { AppQuestion, AppQuestionAnswer } from '@/models';

export class RegistrationApi extends BaseApi {
    static resource = 'registrations';

    get endpoints() {
        return {
            ...super.endpoints,
            get: this.get.bind(this),
            patch: this.patch.bind(this),
            getDeclarations: this.getDeclarations.bind(this),
            getQuestions: this.getQuestions.bind(this),
            getConsents: this.getConsents.bind(this),
            postConsent: this.postConsent.bind(this),
            getAnswers: this.getAnswers.bind(this),
            postAnswers: this.postAnswers.bind(this),
            searchAdvisers: this.searchAdvisers.bind(this),
            getTerms: this.getTerms.bind(this),
            acceptTerms: this.acceptTerms.bind(this)
        };
    }

    async get(id, config = { persistent: true }) {
        let url = this.base_url;

        if (id) {
            url += `/${id}`;
        }

        let response = await BaseApi.api.get(url, {}, config);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterGetResponseData(response.data);

            this._updateItemInCollections(id, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async patch(id, data) {
        let url = this.base_url;

        if (id) {
            url += `/${id}`;
        }

        let response = await BaseApi.api.patch(url, data);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            response.data = this._filterPatchResponseData(response.data);

            this._updateItemInCollections(id, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async getDeclarations() {
        let response = await BaseApi.api.get(`${this.base_url}/declarations`);
        return this._respond(response);
    }

    async getQuestions() {
        let response = await BaseApi.api.get(`${this.base_url}/questions`);
        return this._respond(response, () => {
            if (Array.isArray(response.data)) {
                response.data = response.data.reduce((questions, question) => {
                    if ('answer_type' in question && question.answer_type === 'MULTIPLE_CHOICE') {
                        question.answers = question.multiple_choice.map(answer => {
                            return new AppQuestionAnswer({ ...answer, text: answer.answer });
                        });
                        delete question.multiple_choice;
                    }

                    if ('tags' in question && Array.isArray(question.tags)) {
                        for (const tag of question.tags) {
                            if (!(tag.name in questions)) {
                                questions[`${tag.name}`] = [];
                            }

                            questions[`${tag.name}`].push(new AppQuestion(question));
                        }
                    }

                    return questions;
                }, {});
            }
        });
    }

    async getConsents(investorId = null) {
        let filters = null;

        if (investorId) {
            filters = {
                investor_id: investorId
            };
        }

        let response = await BaseApi.api.get(`${this.base_url}/consents`, filters);
        return this._respond(response);
    }

    async postConsent(consent) {
        let response = await BaseApi.api.post(`${this.base_url}/consents`, consent);
        return this._respond(response);
    }

    async getAnswers() {
        let response = await BaseApi.api.get(`${this.base_url}/answers`);
        return this._respond(response);
    }

    async postAnswers(answers) {
        let response = await BaseApi.api.post(`${this.base_url}/answers`, answers);
        return this._respond(response);
    }

    async searchAdvisers(criteria, filters = {}) {
        if (criteria) {
            filters = {
                ...this._toSearchFilters(criteria, filters, 'company_name'),
                ...filters
            };
        }

        return await BaseApi.api.get(`${this.base_url}/advisers`, {
            limit: 100,
            ...filters
        });
    }

    async getTerms() {
        let response = await BaseApi.api.get(`${this.base_url}/terms`);
        return this._respond(response);
    }

    async acceptTerms() {
        let response = await BaseApi.api.post(`${this.base_url}/terms`);
        return this._respond(response);
    }
}

export default RegistrationApi;
