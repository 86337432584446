<template>
    <v-app
        :class="classList"
        :style="{
            '--v-app-bar-height': '6rem',
            '--v-app-breadcrumbs-height': '2rem',
            '--v-app-risk-warning-height': '2rem',
            '--v-app-compatibility-warning-height': '2rem',
            '--v-container-height': containerHeight,
            '--v-app-bar-top': appBarTop,
            '--v-app-main-padding-top': appMainPaddingTop
        }"
    >
        <app-menu-panel v-if="showApp && $vuetify.breakpoint.mobile" />
        <app-snack-queue />
        <app-dialog-queue />
        <app-filter-panels />
        <app-media-library />

        <v-snackbar
            v-if="$store.getters['app/mock_api']"
            :value="showMockingApiAlert"
            :timeout="-1"
            absolute
            centered
            top
            :elevation="2"
            color="primaryText"
            style="z-index: 8"
        >
            <template v-if="isStaging()">This is a staging environment with a mocked API</template>
            <template v-else>This is a local development environment with a mocked API</template>

            <template #action>
                <app-button icon="close" color="white" small @click="showMockingApiAlert = false" />
            </template>
        </v-snackbar>

        <template v-if="showApp">
            <app-risk-warning v-if="showRiskWarning" />
            <app-compatibility-warning v-if="showCompatibilityWarning" />
            <app-bar v-if="showAppBar" />
            <app-breadcrumbs v-if="showCrumbs" />
            <v-main class="app-main" :class="{ 'app-main--logged-in': showAppBar }">
                <transition appear name="fade" mode="out-in">
                    <router-view />
                </transition>

                <app-dialog
                    v-model="recategorised"
                    size="large"
                    :persistent="false"
                    :cancel-button-text="$t('proceed')"
                    hide-continue-button
                    :on-cancel-fn="() => (recategorised = false)"
                    :title="$t('thank_you_for_completing_your_categorisation')"
                    :overline="$t('categorisation')"
                >
                    <v-card-text class="px-6">
                        {{ $t('were_required_to_revalidate_this_information_with_you_every_12_months') }}
                    </v-card-text>
                </app-dialog>

                <app-user-feedback />
                <app-platform-modal />
                <app-progress-queue />
                <app-terms-modal
                    v-if="!Auth().onboarding"
                    v-model="requireTermsAcceptance"
                    @accepted="replaceQueryParam('tcs', null)"
                />

                <app-session-expired v-model="sessionExpired" />
            </v-main>
        </template>

        <app-dialog v-else-if="showAccountSwitcher" persistent hide-actions :title="$t('please_select_an_account')">
            <v-card-text>
                <app-account-switcher-list />
            </v-card-text>
        </app-dialog>

        <v-overlay v-else color="background" opacity="1">
            <v-progress-circular
                :size="100"
                :width="7"
                :color="Auth().branding().colour('primaryBackground')"
                indeterminate
            />
        </v-overlay>

        <app-loading />
    </v-app>
</template>

<script>
import { isBrowserCompatible, isBrowserVersionCompatible } from '@/router/middleware/checkBrowserCompatibility';
import isStaging from '@/lib/helpers/isStaging';
import freshdesk from '@/mixins/freshdesk';

export default {
    name: 'App',
    components: {
        AppBar: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppBar'
            ),
        AppBreadcrumbs: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppBreadcrumbs'
            ),
        AppRiskWarning: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppRiskWarning'
            ),
        AppCompatibilityWarning: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppCompatibilityWarning'
            ),
        AppAccountSwitcherList: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppAccountSwitcherList'
            ),
        AppFilterPanels: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppFilterPanels'
            ),
        AppSnackQueue: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppSnackQueue'
            ),
        AppDialog: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppDialog'
            ),
        AppDialogQueue: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppDialogQueue'
            ),
        AppMenuPanel: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppMenuPanel'
            ),
        AppMediaLibrary: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppMediaLibrary'
            ),
        AppUserFeedback: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppUserFeedback'
            ),
        AppSessionExpired: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppSessionExpired'
            ),
        AppLoading: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppLoading'
            ),
        AppPlatformModal: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppPlatformModal'
            ),
        AppProgressQueue: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppProgressQueue'
            ),
        AppTermsModal: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppTermsModal'
            )
    },
    mixins: [freshdesk],
    data: () => ({
        recategorised: false,
        requireTermsAcceptance: false,
        sessionExpired: false,
        showMockingApiAlert: true,
        isStaging
    }),
    computed: {
        classList() {
            let classList = [];

            if (this.showRiskWarning) {
                classList.push('app--risk-warning');
            }

            if (this.showCompatibilityWarning) {
                classList.push('app--compatibility-warning');
            }

            return classList.join(' ');
        },
        isPublic() {
            return this.$route.meta.public === true;
        },
        showApp() {
            if (this.isPublic) {
                return true;
            }

            if (this.Auth().onboarding) {
                return true;
            }

            if (this.Auth().is_logged_in && this.Auth().is_initialised && this.Auth().has_branding) {
                return true;
            }

            return false;
        },
        showAppBar() {
            if (this.$route.name === 'FatalError') {
                return false;
            }

            if (this.$route.name === 'PageError') {
                return false;
            }

            if (this.Auth().is_logged_in && this.Auth().is_initialised) {
                return true;
            }

            return false;
        },
        showRiskWarning() {
            return (
                this.showAppBar &&
                this.$route &&
                this.$route.name &&
                this.$route.name.startsWith('Offer') &&
                this.isInvestor()
            );
        },
        showCompatibilityWarning() {
            return !isBrowserCompatible() || !isBrowserVersionCompatible();
        },
        showCrumbs() {
            return this.showAppBar && this.$store.getters['preferences/show_breadcrumbs'];
        },
        showAccountSwitcher() {
            if (this.Auth().is_logged_in && !this.Auth().is_initialised) {
                return true;
            }

            return false;
        },
        containerHeight() {
            let height = '100vh';

            if (this.showAppBar) {
                height = `calc(${height} - var(--v-app-bar-height))`;
            }

            if (this.showRiskWarning) {
                height = `calc(${height} - var(--v-app-risk-warning-height))`;
            }

            if (this.showCompatibilityWarning) {
                height = `calc(${height} - var(--v-app-compatibility-warning-height))`;
            }

            if (this.showCrumbs) {
                height = `calc(${height} - var(--v-app-breadcrumbs-height))`;
            }

            return height;
        },
        appBarTop() {
            let top = '0px';

            if (this.showRiskWarning) {
                top = `calc(${top} + var(--v-app-risk-warning-height))`;
            }

            if (this.showCompatibilityWarning) {
                top = `calc(${top} + var(--v-app-compatibility-warning-height))`;
            }

            return top;
        },
        appMainPaddingTop() {
            let paddingTop = '0px';

            if (this.showAppBar) {
                paddingTop = `calc(${paddingTop} + var(--v-app-bar-height))`;
            }

            if (this.showCrumbs) {
                paddingTop = `calc(${paddingTop} + var(--v-app-breadcrumbs-height))`;
            }

            return paddingTop;
        }
    },
    watch: {
        recategorised(v) {
            if (!v) {
                this.replaceQueryParam('recategorised');
            }
        },
        '$route.query.tcs'(tcs) {
            this.requireTermsAcceptance = !!tcs;
        }
    },
    mounted() {
        if (this.getQueryParam('recategorised')) {
            this.recategorised = true;
        }

        if (this.getQueryParam('tcs')) {
            this.requireTermsAcceptance = true;
        }

        window.addEventListener('focus', this.handleTabFocus);
        window.addEventListener('blur', this.handleTabBlur);
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeDestroy() {
        window.removeEventListener('focus', this.handleTabFocus);
        window.removeEventListener('blur', this.handleTabBlur);
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
    },
    methods: {
        async handleTabFocus() {
            if (this.isPublic) {
                return;
            }

            try {
                await this.Auth().ping();
                this.sessionExpired = false;
            } catch (error) {
                if (error?.response?.status === 401) {
                    this.sessionExpired = true;
                } else {
                    this.sessionExpired = false;
                }
            }
        },
        handleTabBlur() {
            this.$store.dispatch('keyboard/clear');
        },
        handleKeyDown(event) {
            this.$store.dispatch('keyboard/press', event);
        },
        handleKeyUp(event) {
            this.$store.dispatch('keyboard/release', event);
        }
    }
};
</script>
