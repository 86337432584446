import money from '@/lib/money';

export class Calculator {
    static $m = money;
    get $m() {
        return money;
    }

    constructor(properties = {}) {
        for (const key in properties) {
            if (properties[`${key}`] !== null && properties[`${key}`] !== undefined) {
                this[`${key}`] = properties[`${key}`];
            }
        }
    }

    toMoney(amount = 0) {
        return { currency: this._currency, amount };
    }

    fromMoney(money) {
        if (typeof money === 'number') {
            return money;
        } else if (typeof money === 'string') {
            return parseFloat(money);
        } else if (typeof money === 'object' && money && money.amount) {
            return money.amount;
        }
        return 0;
    }

    recalculate() {
        throw new Error('Subclasses must implement the recalculate() method');
    }
}

export default Calculator;
